import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

const Container = styled.div({
  gridColumn: 'page-start / page-end',
  paddingTop: rhythm(4),
  paddingBottom: rhythm(4),
});

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const { siteName } = data.site.siteMetadata;

    return (
      <Layout location={this.props.location} siteName={siteName}>
        <SEO title='Page Not Found' />
        <Container>
          <h1>Page Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist ... the sadness.</p>
        </Container>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;
